import React from "react"
import styled from "styled-components"

import Layout from "../components/Layout/Layout"
import { H1 } from "../components/BaseElements/BaseElements"
import Seo from "../components/seo"
import background from "../images/background.jpg"

const NotFoundPage = () => (
  <Layout>
    <Seo title="Page Not Found" />
    <Content>
      <H1>404 PAGE NOT FOUND</H1>
    </Content>
    <TreeBg />
  </Layout>
)

const Content = styled.div`
  height: calc(100vh - 202px);
  min-height: 500px;
  padding: 100px 0;
  color: #fff;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
`

const TreeBg = styled.div`
  top: 0;
  height: 100vh;
  width: 100vw;
  background: url(${background});
  background-size: cover;
  background-position: center;
  position: fixed;
  z-index: 1;
  opacity: 1;
  transition: opacity 0.3s ease-out;
`

export default NotFoundPage
